<template>
    <div>
        <el-row :gutter="20">
            <el-col :span="24">
                <el-table
                v-if="tabPattern == 1 && !searchComponent && viewer.showFiles.length != 0"
                :data="viewer.showFiles"
                size="small"
                height="calc(100vh - 265px)"
                style="width: 100%;"
                @row-click="handleRowClickEvent">
                    <el-table-column
                        label="文件名"
                        width="300">
                        <template slot-scope="scope">
                            <div class="file-box">
                                <img :src="coverImg(scope.row)" v-if='scope.row.type==0' lazy>
                                <img :src="thumbIcon(scope.row)" v-else lazy>
                                <span>{{scope.row.name}}</span>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column
                        label="文件类型"
                        width="100"
                        align="center">
                        <template slot-scope="scope">
                            {{ scope.row.suffix || "文件夹" }}
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="ownerName"
                        label="所有者"
                        align="center">
                    </el-table-column>
                    <el-table-column
                        label="操作时间"
                        align="center">
                        <template slot-scope="scope">
                            {{scope.row.createDate |date}}
                        </template>
                    </el-table-column>
                   <el-table-column
                   label="操作"
                    align="center">
                        <template slot-scope="scope"  v-if='checkIsMy(scope.row)' >
                            <el-button type="text" size="small" @click.stop="handleCommand({type: 'about', row: scope.row})">详情</el-button>
                            <el-button type="text" size="small" @click.stop="handleCommand({type: 'rename', row: scope.row})">重命名</el-button>
                            <el-button type="text" size="small" @click.stop="handleCommand({type: 'download', row: scope.row.id})">下载</el-button>
                            <el-button type="text" size="small" @click.stop="handleCommand({ type: 'delete', row: scope.row})">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>

                <el-row :gutter="20" class="card"  v-if="tabPattern != 1 && !searchComponent && viewer.showFiles.length != 0">
                   <template v-if="viewer.showFiles.length > 0">
                        <el-col :span="6" style="margin-bottom: 15px;" v-for="orgCardItem in viewer.showFiles" :key="orgCardItem.id">   
                            <el-card class="org-doc-card" :body-style="{ padding: '0px' }" @click.native="handleRowClickEvent(orgCardItem)">
                                <div class="document-top">
                                    <!-- 图片展示 -->
                                    <img :src="coverImg(orgCardItem)" v-if='orgCardItem.type==0' style="width:80px;height:70px;margin-top:65px" alt="orgCardItem.name" lazy>
                                    <img :src="coverImg(orgCardItem)" v-else style="max-height: 100%;width:100%"  alt="orgCardItem.name" lazy>
                                </div>
                                <div class="document-middle">
                                    <span :title="orgCardItem.name">{{orgCardItem.name}}</span>
                                    <div>
                                        <span>
                                            <i class="el-icon-user-solid"></i>
                                            {{orgCardItem.ownerName}}
                                        </span>
                                        <span>{{ orgCardItem.suffix || "文件夹" }}</span>
                                    </div>
                                    <div class="dowm-select" v-if='checkIsMy(orgCardItem)'>
                                        <!-- {{ orgCardItem.miji == 0 ? '普通': orgCardItem.miji == 1 ? '保密' : orgCardItem.miji == 2 ? '机密':'绝密'}} -->
                                        <span>{{orgCardItem.createDate | date}}</span>
                                        <el-dropdown>
                                            <span class="el-dropdown-link">
                                            <i class="el-icon-more el-icon--right" title="更多"></i>
                                            </span>
                                            <el-dropdown-menu slot="dropdown">
                                                <el-dropdown-item icon="el-icon-edit-outline" @click.native="handleCommand({type: 'about', row: orgCardItem})">详情</el-dropdown-item>
                                                <el-dropdown-item icon="el-icon-edit" @click.native="handleCommand({type: 'rename', row: orgCardItem})">重命名</el-dropdown-item>
                                                <el-dropdown-item icon="el-icon-download" @click.native="handleCommand({type: 'download', row: orgCardItem.id})">下载</el-dropdown-item>
                                                <el-dropdown-item icon="el-icon-document-delete" @click.native="handleCommand({ type: 'delete', row: orgCardItem})">删除</el-dropdown-item>
                                            </el-dropdown-menu>
                                        </el-dropdown>
                                    </div>
                                </div>
                            </el-card>
                        </el-col>
                   </template>
                  
                </el-row>

                <!-- 检索组件 -->
                <SearchComponents v-if='searchComponent' :viewer='viewer'></SearchComponents>

                <div class="no-data-box" v-if='viewer.showFiles.length == 0'>
                    <img src="@/assets/no-file.svg" alt="">
                    <span>暂无数据</span>
                </div>
                <el-pagination
                class="pagebox"
                v-if="viewer.showFiles.length > 0"
                @current-change="handleCurrentChange"
                :current-page="formLine.pageNum"
                :page-size="formLine.pageSize"
                layout="total,  prev, pager, next, jumper"
                :total="total">
                </el-pagination>
            </el-col>
        </el-row>

        <UploadFile ref="uploadFile" :uploadInfos="activeInfo" @refreshOrgDocListData="loadAndRefresh"></UploadFile>
        <!-- 文件名称操作 -->
        <FolderName ref="folderName" :viewerKey="vkey" :renameId="renameId" datatype="doc"></FolderName>
        <FilePropsDialog ref="filePropsDialog"></FilePropsDialog>
        <FileDownloadDialog ref="fileDownloadDialog" ></FileDownloadDialog>
          <!-- 选择我的文件 -->
        <SelectMyDocument ref='SelectMyDocument' @SelectMyDocument='SelectToMyDocument'></SelectMyDocument>
      
    </div>
</template>
<script>
import {documentAPI } from 'liankong-ui-api' 
import defaultImg from '@/assets/folder.png'
import morenImg from '@/assets/moren.png'

import UploadFile from "@/components/UploadFile.vue"
import FolderName from "../files/FolderName.vue";
import FilePropsDialog from "../files/FileProps.vue";
import FileDownloadDialog from "../files/Download.vue";
import SearchComponents from '../files/Search.vue'
import SelectMyDocument from '../onlineEditing/components/SelectMyDocument.vue';


import iconDefault from '@/assets/img/icon-def.png'
import iconUnknown from '@/assets/img/icon-unknown.png'
import iconImage from '@/assets/img/icon-picture.png'
import iconOFD from '@/assets/img/icon-ofd.png'
import iconAudio from '@/assets/img/icon-audio.png'
import iconWord from '@/assets/img/icon-word.png'
import iconPpt from '@/assets/img/icon-ppt.png'
import iconPdf from '@/assets/img/icon-pdf.png'
import iconExcl from '@/assets/img/icon-excl.png'
import iconMP3 from '@/assets/doc-icon/mp3.png'
import iconMP4 from '@/assets/doc-icon/mp4.png'
import iconZIP from '@/assets/doc-icon/zip.png'

//后缀映射
const suffixIconCache = {
  def: iconDefault,
  unknown: iconUnknown,

  png: iconImage,
  jpg: iconImage,
  jpeg: iconImage,
  bmp: iconImage,

  ofd: iconOFD,

  doc: iconWord,
  DOC: iconWord,
  docx: iconWord,

   mp3: iconMP3,
  mp4: iconMP4,
  zip: iconZIP,
  ppt:iconPpt,
  pptx:iconPpt,
  pdf:iconPdf,
  xls:iconExcl,
  xlsx:iconExcl
}
export default {
    data(){
        return{
            activeName: '', // 选中名称
            activeInfo: {}, // 选中数据
            hoverIndex: 0, // 显示标识
            colorArray: ['#C7CBE1', '#D8C5B9', '#E4A88A', '#7FCB97', '#DBA9E0', '#C28DAC', '#F58686', '#EBEA95', '#69A07F', '#E43434'], // 颜色
            vkey: 'groupDoc',
            parentId: 0, // 父文件夹
            type: 3, // 
            //选择配置
            options: {
                //文件数
                count: Number.MAX_VALUE,
                /**
                 * 筛选类型
                 * 默认包含文件夹和文件
                 * 0：文件夹
                 */
                type: null,

                //回调函数
                callback: "onFileSelected"
                // callback: "my"
            },
            isCopy: true,
            renameId:'',
            formLine: {
                pageNum: 1,
                pageSize: 10
            },
            tabPattern: 1,
            searchComponent:false,
            keywords:'',
            total:0
        }
    },
    computed: {
        group(){
            let newGroupArray = this.$store.state.newGroup.views['allGroup'].list || []
            // 设置背景颜色
            newGroupArray.forEach((datas, ind) => {
                this.$set(datas, 'colors', this.colorArray[ind])
            })
            return newGroupArray
        },
        viewer() {
            return this.$store.state.doc.viewer[this.vkey]  || []
        },
        coverImg(){
            return (doc) => doc.type == 1 ?(doc.coverImg!=null? this.$options.filters["thumbnailUrl"](doc.coverImg):morenImg ): defaultImg
        },
        user() {
            return this.$store.state.login.userInfo;
        },
    },
    components: {
        UploadFile,
        FolderName,
        FilePropsDialog,
        FileDownloadDialog,
        SearchComponents,
        SelectMyDocument
    },
    watch: {
        // group(newvalue) {
        //     if(newvalue.length > 0) {
        //         // 默认选中第一个
        //         this.activeName = newvalue[0].name;
        //         this.activeInfo = newvalue[0];
        //         this.loadAndRefresh();
        //     }
        // }
    },
    mounted(){
        // this.onLoadGroupDoc();

        this.$ebus.$on('uploadFileBtn', res=>{
            this.$nextTick(()=>{
                this.handleCommand({type: 'upload'})
            })
        })
        this.$ebus.$on('addNewFileBtn', res=>{
            this.$nextTick(()=>{
                this.handleCommand({type: 'mkdir'})
            })
        })
         this.$ebus.$on('myDocumentFileBtn', res=>{
            this.$nextTick(()=>{
                this.handleCommand({type: 'selectMyDocument'})
            })
        })

        this.$ebus.$on('groupDocGoBackBtn', text=>{
            this.doFolderBack()
        })
        this.$ebus.$on('handleClickGroupTreeLeft', ({ item,index })=>{
            if(item){
                this.handleClickOrgTreeLeft(item,index);
                this.$store.commit('clearFolderBreadList')
                this.$store.commit('orgFolderBreadList', {doc: item, tip: '群组文档一级'});
            }else{
                this.viewer.depth = 0;
                this.$store.state.doc.viewer[this.vkey].depth = 0;
                this.$store.state.doc.viewer[this.vkey].paths = [];
                this.$store.state.doc.viewer[this.vkey].showFiles = []
                this.$store.commit('pageBackTextShow', '')
                this.$store.commit('clearFolderBreadList')
            }
            
        })
        this.$ebus.$on('changeSearchOrgDocList', orgSearchValue=>{
            this.searchComponent = orgSearchValue != ''&& orgSearchValue != null ? true:false
            this.keywords = orgSearchValue;
            this.formLine.pageNum = 1;
            this.searchOrgDocEvent(orgSearchValue)
        })
        this.$ebus.$on('callBackTab', tabPattern=>{
            this.tabPattern = tabPattern
        })
        this.$ebus.$on('clickGroupFolderList',item=>{
            this.parentId = 0;
                this.loadAndRefresh();
                this.viewer.depth = 0;
                this.$store.state.doc.viewer[this.vkey].depth = 0;
                this.$store.state.doc.viewer[this.vkey].paths = [];
                this.$store.commit('pageBackTextShow', '')
                this.$store.commit('clearFolderBreadList')
                 this.$store.commit('orgFolderBreadList', {doc: item.data, tip: '群组文档一级'});
        })
        this.$ebus.$on('clickGroupFolder', item=>{
            if(item.title == '群组文档') {
                this.parentId = 0;
                this.loadAndRefresh();
                this.viewer.depth = 0;
                this.$store.state.doc.viewer[this.vkey].depth = 0;
                this.$store.state.doc.viewer[this.vkey].paths = [];
                this.$store.commit('pageBackTextShow', '')
                this.$store.commit('clearFolderBreadList')
            } else {
                this.parentId = item.data.id;
                if (item.data.type == 0) {
                    let orgParamsInfo = {
                        parentId: this.parentId,
                        id: this.activeInfo.id,
                        type: this.type,
                        docType: this.type,
                        rootId: this.activeInfo.id
                    }
                    this.$store.dispatch("doc/enterFolder", {
                        vkey: this.vkey,
                        folder: item.data,
                        orgParamsInfo
                    });
                }
                if(item.data.children.length > 0) {
                    this.$store.commit('folderBreadDeleteNum', { id: item.path, vkey: 'groupDoc'})
                }
            }
        })
    },
    methods: {
        // 保存到组织文档
        SelectToMyDocument(id){
            if(id  != null ){
                documentAPI.copyDocument({
                    sourceDocumentId: id,
                    targetDocumentId: this.parentId,
                    targetOrgId:this.activeInfo.id,
                    docType: 3,
                }).then(res => {
                    if(res.code == 0){
                        this.$message.success('保存成功!')
                        this.loadAndRefresh()
                    }else{
                        this.$message.error(res.message)
                    }
                })
            }
        },
        // 检测是否是自己的文件
        checkIsMy(row){
            return row.ownerId == this.user.id? true : false;
        },
        // 图标
        thumbIcon(doc){
            let iconKey = "unknown"
            if(suffixIconCache.hasOwnProperty(doc.suffix)){
                iconKey = doc.suffix
            }
            return suffixIconCache[iconKey] 
        },
        onLoadGroupDoc(){
            this.$store.dispatch('newGroup/queryChildren', {
                vkey: 'allGroup',
                reset: true,
                query:{
                    type: '',
                    groupType: 1,
                    pageNum: 1,
                    pageSize: 10
                } 
            })
        },
        // 左侧点击
        handleClickOrgTreeLeft(item, index){
            this.activeName = item.name;
            this.activeInfo = item;
            this.hoverIndex = index;

            this.parentId = 0;
            this.viewer.depth = 0;
            this.$store.state.doc.viewer[this.vkey].depth = 0;
            this.$store.state.doc.viewer[this.vkey].paths = [];
            this.loadAndRefresh();
        },
        // 点击
        handleRowClickEvent(row) {
            this.handleCommand({type: 'click', row: row})
        },
        //加载或刷新当前文件夹
        loadAndRefresh() {
            if(this.activeInfo.id){
                this.$store.dispatch("doc/loadChildren", {
                    vkey: this.vkey,
                    reset: true,
                    pageNum: this.formLine.pageNum,
                    pageSize: this.formLine.pageSize,
                    orgParamsInfo: {
                        parentId: this.parentId,
                        id: this.activeInfo.id,
                        type: this.type
                    }
                }).then(res=>{
                     this.total = this.$store.state.doc.viewer[this.vkey].paging.total;
                    if(res !== true) {
                        this.$message.error(res)
                    }
                })
            }
        },
        /**
         * 点击菜单
         * 对右键菜单、快捷菜单、行菜单进行集中处理
         *
         * 快捷菜单：//TODO 针对当前文件夹，记录在state中，无需明确传递
         * 行菜单：Table行内操作，需要明确知道具体的row
         * 右键菜单：右键事件中进行传递到app.contextMenuClientEvent.data
         *
         */
        handleCommand({ type, row }) {
            switch (type) {
                case "upload":
                    this.$refs["uploadFile"].show(this.vkey);
                break;
                case "mkdir":
                    let params = {
                        parentId: this.parentId,
                        id: this.activeInfo.id,
                        type: this.type,
                        docType: this.type,
                        rootId: this.activeInfo.id
                    }
                    this.$ebus.$emit('clearSelect')
                    this.$refs["folderName"].show({ mode: "new", value: null, params });
                break;
                case "delete":
                    this.loadingShow("删除中...")
                    let _this = this;
                    this.$store.dispatch("doc/checkedRemove", {
                        vkey: this.vkey,
                        id: [row.id]
                    }).then((data) => {
                        _this.loadingHide()
                        if(data === false) {
                            _this.$message.error('无权删除群组下文件')
                        } else{
                            _this.$message.success('删除成功')
                        }
                    }).catch(err => _this.$message.error(err))
                break;
                case "about":
                    this.$refs["filePropsDialog"].show(row,this.vkey);
                break;
                case "rename":
                    this.renameId = row.id;
                    this.$refs["folderName"].show({ mode: "rename", value: row.name });
                break;
                case "download":
                    this.$refs["fileDownloadDialog"].show(row);
                break;
                case "click":
                    if (row.type == 0) {
                        //打开文件夹
                        this.handleEnterFolder(row);
                    } else {
                        if(row.documentId!=null){
                            row.id = row.documentId
                        }
                        this.$ebus.$emit('publicReader',{
                            shareId:row.shareId, 
                            id:row.documentId || row.id,
                            documentId: row.documentId,
                            name:row.name,
                            suffix:row.suffix
                        })
                        
                    }
                break;
                case 'selectMyDocument':
                    if(this.$refs.SelectMyDocument){
                        this.$refs.SelectMyDocument.show('file','','orgDocument');
                    }
                break;
            }
        },
        //分页
        handleCurrentChange(num) {
            this.formLine.pageNum = num;
            this.searchOrgDocEvent(this.keywords,num);
        },
        //进入指定目录
        handleEnterFolder(folder) {
            // this.rootId = folder.id;
            this.parentId = folder.id;
            // 文件夹的名称存起来
            this.$store.commit('folderBreadList', {doc: folder, tip: '群组文档'});
            if (folder) {
                this.docuInfoId = folder
                if (folder.type == 0) {
                    let orgParamsInfo = {
                        parentId: this.parentId,
                        id: this.activeInfo.id,
                        type: this.type,
                        docType: this.type,
                        rootId: this.activeInfo.id
                    }
                    this.$store.dispatch("doc/enterFolder", {
                        vkey: this.vkey,
                        folder,
                        orgParamsInfo
                    });
                }
            } else {
                this.$store.dispatch("doc/enterFolder");
            }

            if(this.viewer.depth > 0) {
                this.$store.commit('pageBackTextShow', '群组文档')
            }
        },
        //文件目录返回
        doFolderBack() {
            this.parentId = 0;
            this.$store.dispatch("doc/backFolder", {
                vkey: this.vkey,
            })
            this.loadAndRefresh()
            if(this.viewer.depth == 0) {
                this.$store.commit('pageBackTextShow', '')
            }

            this.$store.commit('backFolerBread')
        },
        // 搜索
        searchOrgDocEvent(val,num) {
            if(val != '') {
                let queryInfo= this.$store.state.index.viewer['groupDoc'].query;
                queryInfo.keyword = val;
                queryInfo.groupId = this.activeInfo.id;
                this.$store.dispatch('index/loadByQuery', {
                    vkey: this.vkey,
                    reset: true,
                    query: queryInfo,
                    parameter:{
                        pageNum : num ? num : 1,
                        pageSize: this.formLine.pageSize
                    }
                }).then(res=>{
                    this.total = this.$store.state.index.viewer['groupDoc'].paging.total;
                    this.$store.state.doc.viewer['groupDoc'].showFiles = this.$store.state.index.viewer['groupDoc'].showFiles;
                })
            } else {
                this.loadAndRefresh();
            }
            
        }
    },
    beforeDestroy() {
        this.$ebus.$off('uploadFileBtn');
        this.$ebus.$off('addNewFileBtn');
        this.$ebus.$off('groupDocGoBackBtn');
        this.$ebus.$off('handleClickGroupTreeLeft');
        this.$ebus.$off('changeSearchOrgDocList');
        this.$ebus.$off('callBackTab');
        this.$ebus.$off('clickGroupFolder');
    }
}
</script>
<style lang="less" scoped>
.structure-tree {
    .trees-head {
        display: flex;
        justify-content: space-between;
        height: 40px;
        line-height: 40px;
        h4 {
            margin: 0;
            padding: 0;
        }
        /deep/ .el-button {
            font-size: 18px;
            padding: 10px 0px;
            .el-icon-plus {
                font-weight: bolder;
            }
        }
    }
    .list {
        margin: 0;
        padding: 0;
        li {
            list-style: none;
            margin-top: 10px;
            cursor: pointer;
            div {
                padding: 5px 10px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                p{
                    padding: 0;
                    margin: 0;
                    span:nth-child(1) {
                        display: inline-block;
                        width: 40px;
                        height: 40px;
                        line-height: 40px;
                        border-radius: 50%;
                        background: #C7CBE1;
                        text-align: center;
                        margin-right: 10px;
                        color: #fff;
                    }
                }
                >span {
                    display: inline-block;
                    border: 1px solid #c2e7b0;
                    height: 20px;
                    line-height: 20px;
                    padding: 0px 3px;
                    border-radius: 4px;
                    font-size: 12px;
                    color: #67C23A !important;
                    background: #f0f9eb;
                }
            }
            .active {
                background: #F2F8FF;
                span:nth-child(2) {
                    color: #409EFF;
                }
            }
        }
        li:nth-child(1) {
            margin-top: 0px;
        }
    }
    .no-data-box {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        img {
            width: 120px;
            height: 120px;
        }
    }
}
.right-btns {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 20px;
    color: #666666;
    cursor: pointer;
}
.card {
    height: calc(100vh - 265px);
    overflow: auto;
    // .el-col-5 {
    //     width: 19.83%;
    // }
}
/deep/ .el-table {
    .file-box {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        img {
            width: 25px;
            height: 25px;
            margin-right: 5px;
        }
        span {
            display: block;
            width: 240px;
            height: 25px;
            overflow: hidden;
            text-overflow: ellipsis;
            // display: -webkit-box;
            // -webkit-line-clamp: 1;
            white-space: nowrap;
            // -webkit-box-orient: vertical;
        }
    }
    .el-table__header-wrapper {
        .has-gutter {
            tr th{
                border:none;
                background: #F2F2F2;
                color: #666;
            }
        }
    }
    .el-table__body-wrapper {
        tr td {
            border:none;
            padding: 12px 0;
        }
    }
    &::before {
        height: 0px;
    }
}
.org-doc-card {
    height: 300px;
    padding: 10px;
    box-sizing: border-box;
    .document-top {
        height: 200px;
        line-height: 200px;
        text-align: center;
        img {
            width: 100%;
            height: 100%;
        }
    }
    .document-middle {
        margin: 10px;
        >span {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            word-break: break-all;
            margin-bottom: 5px;
        }
        div {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 14px;
            .el-icon-star-on {
                color: #409EFF;
                font-size: 16px;
            }
        }
        .dowm-select {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 7px;
            .el-dropdown {
                .el-icon-more{
                    color: #409EFF;
                }
            }
        }
    }
}
.pagebox {
    margin-top: 20px;
    text-align: left;
}
.no-data-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2%;
    margin-right: 10%;
    img {
        width: 380px;
        height: 280px;
    }
    span {
        color: #bdc1cd;
        font-size: 16px;
        margin-top: 15px;
        font-weight: 100px;
    }
}
</style>